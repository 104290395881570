<!-- 退役军人新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople">选择居民</a-button>
      </div>

      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <!-- 家庭情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">家庭情况</div>
      </div>
      <!-- 家庭情况 -->
      <ax-form ref="form1" :formBuilder="formBuilder2">
        <!-- 家庭成员1 -->
        <div slot="familyName1" style="display: flex">
          <a-input :value="familyName1" placeholder="请选择"></a-input>
          <ax-button @click="chooseFamilyName1" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
        <!-- 家庭成员2 -->
        <div slot="familyName2" style="display: flex">
          <a-input :value="familyName2" placeholder="请选择"></a-input>
          <ax-button @click="chooseFamilyName2" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
        <!-- 家庭成员3 -->
        <div slot="familyName3" style="display: flex">
          <a-input :value="familyName3" placeholder="请选择"></a-input>
          <ax-button @click="chooseFamilyName3" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
      </ax-form>
      <!-- 服役情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">服役情况</div>
      </div>
      <!-- 服役情况 -->
      <ax-form ref="form2" :formBuilder="formBuilder3"> </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"
    ></choose-people>
    <!-- 选择家庭成员1 -->
    <choose-family-name-1
      ref="chooseFamily1"
      @choose="receiveFamily1"
    ></choose-family-name-1>
    <!-- 选择家庭成员2 -->
    <choose-family-name-2
      ref="chooseFamily2"
      @choose="receiveFamily2"
    ></choose-family-name-2>
    <!-- 选择家庭成员3 -->
    <choose-family-name-3
      ref="chooseFamily3"
      @choose="receiveFamily3"
    ></choose-family-name-3>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "详细地址",
    type: "",
    model: "cellName",
    options: {placeholder:'请选择详细地址'},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList2 = [
  {
    label: "人口数量",
    type: "inputNumber",
    model: "familySize",
    options: { placeholder: "请输入人口数量" },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "家庭成员1",
    type: "",
    model: "familyName1",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "familyPhone1",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "家庭成员2",
    type: "",
    model: "familyName2",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "familyPhone2",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "家庭成员3",
    type: "",
    model: "familyName3",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "familyPhone3",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList3 = [
  {
    label: "入伍时间",
    type: "datePicker",
    model: "enlistmentTime",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "退役时间",
    type: "datePicker",
    model: "retirementTime",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "服役年限",
    type: "",
    model: "serviceLength",
    options: { placeholder: "请输入服役年限" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "服役单位",
    type: "",
    model: "serviceUnit",
    options: { placeholder: "请输入服役单位" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "所属职务",
    type: "",
    model: "position",
    options: { placeholder: "请输入所属职务" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "退伍证编号",
    type: "",
    model: "dischargeCardNumber",
    options: { placeholder: "请输入退伍证编号" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "服役期间的荣誉与奖励",
    type: "textarea",
    model: "honorAward",
    options: { placeholder: "请输入服役期间的荣誉与奖励", minRows: 2 },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "服役期间是否受到处分",
    type: "textarea",
    model: "punishment",
    options: { placeholder: "请输入服役期间是否受到处分", minRows: 2 },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "参与重大任务或战役",
    type: "textarea",
    model: "participateTask",
    options: { placeholder: "请输入参与的重大任务或战役", minRows: 2 },
    col: { span: 24 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
import ChoosePeople from "./choosePeople.vue";
import ChooseFamilyName1 from "./chooseFamilyName1.vue";
import ChooseFamilyName2 from "./chooseFamilyName2.vue";
import ChooseFamilyName3 from "./chooseFamilyName3.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
    ChooseFamilyName1,
    ChooseFamilyName2,
    ChooseFamilyName3,
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      fileIds: [], //图片数组
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 160, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 160 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 160 },
        { col: { span: 12 } }
      ),
      familyName: [], //家庭成员姓名
      familyPhone: [], //家庭成员电话
      baseInfoValue: {}, //基本信息
      familySituation: {}, //家庭情况
      serviceStatus: {}, // 服役情况
      familyName1: "",
      familyName2: "",
      familyName3: "",
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 打开选择居民弹窗
    chooseFamilyName1() {
      this.$refs.chooseFamily1.openModal();
    },
    // 打开选择居民弹窗
    chooseFamilyName2() {
      this.$refs.chooseFamily2.openModal();
    },
    // 打开选择居民弹窗
    chooseFamilyName3() {
      this.$refs.chooseFamily3.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 接收家庭成员1
    receiveFamily1(item) {
      if (item) {
        this.familyName1 = item.residentName;
        this.$refs.form1.setFieldsValue({ familyPhone1: item.phone });
      }
    },
    // 接收家庭成员2
    receiveFamily2(item) {
      if (item) {
        this.familyName2 = item.residentName;
        this.$refs.form1.setFieldsValue({ familyPhone2: item.phone });
      }
    },
    // 接收家庭成员3
    receiveFamily3(item) {
      if (item) {
        this.familyName3 = item.residentName;
        this.$refs.form1.setFieldsValue({ familyPhone3: item.phone });
      }
    },
    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.title = record.title;
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
      });
      if (record.id) {
        this.noChoice = false
        this.id = record.id;
        this.businessId = record.residentId;
        this.getPersonInfo(record.residentId);
        this.getVeteranInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$refs.form2.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {},
    // 身份证号改变的回调
    identityCardChange(value, key) {
      switch (key) {
        case "identityCard":
          this.calculateIDCardInfo(value);
          break;
      }
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 提交
    async onSubmit() {
      if (this.noChoice) {
        this.$message.warning("请先选择人员");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        this.baseInfoValue.residentId = this.businessId;
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.familySituation = value1;
            this.familyName.push(
              this.familyName1,
              this.familyName2,
              this.familyName3
            );
            this.familyPhone.push(
              value1.familyPhone1,
              value1.familyPhone2,
              value1.familyPhone3
            );
            this.familySituation.familyName = this.familyName.join(',');
            this.familySituation.familyPhone = this.familyPhone.join(',');
            delete this.familySituation.familyPhone1;
            delete this.familySituation.familyPhone2;
            delete this.familySituation.familyPhone3;
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(value2);
                this.serviceStatus = value2;
                const totalValue = {
                  ...this.baseInfoValue,
                  ...this.familySituation,
                  ...this.serviceStatus,
                };
                console.log(totalValue, "375新增数据");
                if (this.id) {
                  totalValue.id = this.id;
                  const res = await api.updateveteran(totalValue);
                  console.log(res);
                  if (res.status === 200) {
                    this.$message.success("修改成功");
                    this.closeModal();
                    this.$refs.form1.resetFields();
                    this.$refs.form2.resetFields();
                  }
                } else {
                  const res = await api.addveteran(totalValue);
                  console.log(res);
                  if (res.status === 200) {
                    this.$message.success("新增成功");
                    this.closeModal();
                    this.$refs.form1.resetFields();
                    this.$refs.form2.resetFields();
                  }
                }
              });
            }
          });
        }
      });
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
       this.$refs.form.setFieldsValue({ cellName: res.data.cellName ? `${res.data.cellName}/${res.data.unitNum}/${res.data.floorNum}/${res.data.unitNum}/${res.data.buildingNum}/${res.data.accountNum}` : ''});
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 根据id获取退伍军人详情
    async getVeteranInfo(id) {
      const res = await api.getveteranById(id);
      this.familyName1 = res.data.familyName.split(',')[0]
      this.familyName2 = res.data.familyName.split(',')[1]
      this.familyName3 = res.data.familyName.split(',')[2]
      this.$refs.form1.setFieldsValue({ familyPhone1: res.data.familyPhone.split(',')[0] })
      this.$refs.form1.setFieldsValue({ familyPhone2: res.data.familyPhone.split(',')[1] })
      this.$refs.form1.setFieldsValue({ familyPhone3: res.data.familyPhone.split(',')[2] })
      this.$refs.form1.setFieldsValue(res.data);
      this.$refs.form2.setFieldsValue(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>