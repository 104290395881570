import { render, staticRenderFns } from "./addPeople copy 2.vue?vue&type=template&id=120c43f1&scoped=true"
import script from "./addPeople copy 2.vue?vue&type=script&lang=js"
export * from "./addPeople copy 2.vue?vue&type=script&lang=js"
import style0 from "./addPeople copy 2.vue?vue&type=style&index=0&id=120c43f1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "120c43f1",
  null
  
)

export default component.exports