<!-- 选择居民弹窗-->
<template>
  <div>
    <el-dialog
      title="选择居民"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">人员列表</div>
        </div>
      </div>
      <!-- 基本信息 -->
      <ax-table
        ref="tableBox"
        :columns="columns"
        :show-search="true"
        :searchForm="searchForm"
        :searchActions="searchActions"
        :showToolBar="false"
        :row-selection="{
          type: 'radio',
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: getCheckboxProps,
        }"
        :dataSourceApi="dataSourceApis"
        :dataSourceParams="dataSourceParams"
        @add="transmissionId"
      >
      </ax-table>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "searchText",
    options: { placeholder: "输入关键字进行搜索" },
    formItem: {},
    col: { span: 12 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: ["selectedPeopleList"],
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 110,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: true,
            width: 150,
          },
        ],
        false
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
        { name: "add", text: "保存" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      this.$refs.tableBox.getDataSource(obj);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.$nextTick(() => {
        this.selectedRowKeys = [];
        this.getTableListData()
      });
    },
    getCheckboxProps(record) {
      console.log(record.id, "121");
      return {
        props: {
          disabled: this.selectedPeopleList
            .map((user) => {
              return user.residentId;
            })
            .some((item) => item === record.id),
        },
      };
    },

    // 关闭弹窗
    closeModal() {
      this.visible = false;
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(selectedRowKeys, "95");
      this.selectedRowKeys = selectedRowKeys;
    },
    // 保存
    transmissionId() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning("请选择居民");
      } else {
        this.visible = false;
        this.$emit("receive", this.selectedRowKeys);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}
</style>